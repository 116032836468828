// firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBQciL13iYQ6xLl9_BQC_gRXCFG2nceV1s",
  authDomain: "bloomhero.firebaseapp.com",
  databaseURL: "https://bloomhero-default-rtdb.firebaseio.com",
  projectId: "bloomhero",
  storageBucket: "bloomhero.appspot.com",
  messagingSenderId: "683227345764",
  appId: "1:683227345764:web:b3b18f238a3c7b24fa4071",
  measurementId: "G-M8TXZ5ZML9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };
