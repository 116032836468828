// path: webapp/src/views/productpage.js

import React, {useState} from 'react';
import { Pane, Heading,TableHead, Tablist, Tab, TableCell,TableRow,TableBody,Table, UnorderedList, ListItem, Link, Card, Text, Image } from 'evergreen-ui';
const productPageData = {
    tableOfContents: [
      { id: 'product-description', title: 'Product Description' },
      { id: 'media', title: 'Media' },
      { id: 'health-data', title: 'Health Data' },
      { id: 'how-to-use', title: 'How to Use' },
      { id: 'hazards', title: 'Hazards' },
    ],
    affiliateLinks: [
      { platform: 'Amazon', url: 'amazon-link' },
      { platform: 'Walmart', url: 'walmart-link' },
      { platform: 'Shopify', url: 'shopify-link' },
    ],
    productDescription: {
      specifications: {
        name: 'Cuisinart Breakfast Express Flip 1 Side Belgian Waffle & 1 Side Omelet Maker',
        model: 'WAF-B50',
        brand: 'Cuisinart',
        color: 'Stainless Steel',
        dimensions: '10.88"D x 16.5"W x 10.25"H',
        weight: '12.4 Pounds',
        upc: '086279095732',
        globalTradeItemNumber: '00086279095732',
        material: 'Stainless Steel',
        manufacturer: 'Cuisinart',
        wattage: '1400 watts',
        voltage: '120 Volts',
        cookingCapabilities: [
          '1-inch deep Belgian waffles',
          'Omelets',
          'Pancakes',
          'Frittatas',
          'Fried eggs',
          'Grilled sandwiches',
          'English muffins',
          'Sausages',
        ],
        controls: 'Two separate browning control knobs (one for each side)',
        warranty: 'Limited one-year appliance warranty',
      },
      specialFeatures: [
        'Non-stick coating',
        'Rotary flip feature',
        'Dual cooking surfaces (waffle and omelet)',
        '6 browning settings for each side',
        'LED ready indicator lights',
        '3 audio beep tones',
      ],
      userReviewSummary: [
        'Easy to use and clean',
        'Convenient for making two breakfast items simultaneously',
        'Produces thick and fluffy waffles',
        'Some users noted issues with alignment and cheap-feeling knobs',
        'Generally positive reviews for functionality and convenience',
      ],
    },
    media: {
      images: [
        { src: 'https://www.cuisinart.com/globalassets/cuisinart-image-feed/waf-b50/waf-b50_header.jpg', alt: 'Header image' },
        { src: 'https://www.cuisinart.com/globalassets/cuisinart-image-feed/waf-b50/wafb50_sd_turn_lf_rgb_rgb.jpg', alt: 'Side view, turned left' },
        { src: 'https://www.cuisinart.com/globalassets/cuisinart-image-feed/waf-b50/wafb50_sd_str_open_rgb.jpg', alt: 'Open view' },
        { src: 'https://www.cuisinart.com/globalassets/cuisinart-image-feed/waf-b50/wafb50_sd_straight_rgb_rgb.jpg', alt: 'Straight side view' },
      ],
      videos: [
        { src: 'https://www.youtube.com/watch?v=_rgJiilKWT4', type: 'video/mp4' },
      ],
    },
    healthData: 'Health-related statistics and data. Include charts or graphs here.',
    howToUse: [
      { step: 'Preparation', instructions: [
          'Plug in the appliance and preheat both sides.',
          'Set desired browning levels using the control knobs for each side.',
          'Wait for the ready indicator lights to signal preheating is complete.',
        ],
      },
      { step: 'Making a Waffle', instructions: [
          'Lift the lid of the waffle side.',
          'Pour batter into the center of the waffle grid, spreading it evenly.',
          'Close the lid gently.',
        ],
      },
      { step: 'Flipping the Unit', instructions: [
          'Wait about 2 minutes, then use the handle to rotate the unit 180 degrees.',
        ],
      },
      { step: 'Making an Omelet', instructions: [
          'Lift the lid of the omelet side.',
          'Pour beaten eggs and add desired ingredients.',
          'Close the lid gently.',
        ],
      },
      { step: 'Cooking', instructions: [
          'Allow both sides to cook simultaneously.',
          'The unit will beep three times when cooking is complete.',
        ],
      },
      { step: 'Removing Food', instructions: [
          'Open the lids carefully, using oven mitts if necessary.',
          'Remove the waffle and omelet using non-metallic utensils.',
        ],
      },
      { step: 'Repeat or Switch Off', instructions: [
          'Repeat the process for more waffles/omelets, or turn off and unplug if finished.',
        ],
      },
      { step: 'Cleaning', instructions: [
          'Allow the appliance to cool completely.',
          'Wipe the cooking plates with a damp cloth or paper towel.',
          'Clean the exterior with a soft, dry cloth.',
        ],
      },
    ],
    hazards: [
      {
        category: 'Health Hazards',
        details: [
          { specificHazard: 'Acute Toxicity', riskLevel: 'Low', comments: 'No significant risk when used as intended' },
          { specificHazard: 'Skin Irritation', riskLevel: 'Low', comments: 'Potential for minor burns if touched during operation' },
          { specificHazard: 'Carcinogenicity', riskLevel: 'Negligible', comments: 'No known carcinogenic materials used in construction' },
          { specificHazard: 'Reproductive Toxicity', riskLevel: 'Negligible', comments: 'No known reproductive toxins used in construction' },
        ],
      },
      {
        category: 'Physical Hazards',
        details: [
          { specificHazard: 'Flammability', riskLevel: 'Low', comments: 'Electrical appliance; fire risk if misused or faulty' },
          { specificHazard: 'Reactivity', riskLevel: 'Negligible', comments: 'Not reactive under normal conditions' },
          { specificHazard: 'Corrosivity', riskLevel: 'Negligible', comments: 'Stainless steel construction resistant to corrosion' },
        ],
      },
      {
        category: 'Environmental Hazards',
        details: [
          { specificHazard: 'Aquatic Toxicity', riskLevel: 'Negligible', comments: 'No direct impact on aquatic environments during normal use' },
        ],
      },
      {
        category: 'Other Hazards',
        details: [
          { specificHazard: 'Allergies', riskLevel: 'Low', comments: 'Potential for food allergens if not cleaned properly between uses' },
        ],
      },
    ],
    additionalNotes: [
      'The main hazards associated with this product are related to its use as an electrical cooking appliance, such as the risk of burns or electrical hazards if not used properly.',
      'Users should always follow the manufacturer\'s instructions and safety guidelines to minimize risks.',
      'Keep the appliance away from water sources to prevent electrical hazards.',
      'Ensure proper cleaning between uses to prevent cross-contamination of allergens.',
      'As with any electrical appliance, there\'s a small risk of fire if the product is faulty or misused.',
    ],
  };


 


//   import React, { useState } from 'react';
//   import { Pane, Heading, UnorderedList, ListItem, Link, Card, Text, Image, Tablist, Tab, Table, TableHead, TableBody, TableRow, TableCell } from 'evergreen-ui';
  
  const TableOfContents = ({ items }) => (
    <Pane flexBasis={200} padding={16}>
      <Heading size={600}>Table of Contents</Heading>
      <UnorderedList style={{ listStyleType: 'none', paddingLeft: 0 }}>
        {items.map((item) => (
          <ListItem key={item.id}><Link href={`#${item.id}`}>{item.title}</Link></ListItem>
        ))}
      </UnorderedList>
    </Pane>
  );
  
  const AffiliateLinks = ({ links }) => (
    <Pane marginTop={32}>
      <Heading size={600}>Affiliate Links</Heading>
      {links.map((link, index) => (
        <Card key={index} marginBottom={8} padding={8}>
          <Text>{link.platform}:</Text>
          <Link href={link.url}>Buy on {link.platform}</Link>
        </Card>
      ))}
    </Pane>
  );
 
const ProductSpecifications = ({ specifications, features }) => (
  <Card marginBottom={16} padding={16} flex="1" minWidth={300}>
    <Heading size={600} marginBottom={16}>Product Specifications & Special Features</Heading>
    <Table>
      <TableBody>
        {[
          { label: "Product Name", value: specifications.name },
          { label: "Model", value: specifications.model },
          { label: "Brand", value: specifications.brand },
          { label: "Color", value: specifications.color },
          { label: "Dimensions", value: specifications.dimensions },
          { label: "Weight", value: specifications.weight },
          { label: "Material", value: specifications.material },
          { label: "Wattage", value: specifications.wattage },
          { label: "Voltage", value: specifications.voltage },
          { label: "Controls", value: specifications.controls },
          { label: "Warranty", value: specifications.warranty },
          {
            label: "Cooking Capabilities",
            value: (
              <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                {specifications.cookingCapabilities.map((capability, index) => (
                  <li key={index}>{capability}</li>
                ))}
              </ul>
            )
          },
          {
            label: "Special Features",
            value: (
              <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                {features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            )
          }
        ].map((item, index) => (
          <TableRow key={index} height="auto">
            <TableCell paddingX={8} paddingY={4}>
              <strong>{item.label}</strong>
            </TableCell>
            <TableCell paddingX={8} paddingY={4}>
              {item.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Card>
);

 
  const UsersReviewSummary = ({ reviews }) => (
    <Card marginBottom={16} padding={16} flex="1" minWidth={300}>
      <Heading size={600} marginBottom={8}>User's Review Summary</Heading>
      <UnorderedList style={{ listStyleType: 'none', paddingLeft: 0 }}>
        {reviews.map((review, index) => (
          <ListItem key={index}>{review}</ListItem>
        ))}
      </UnorderedList>
    </Card>
  );
  
  const ProductDescription = ({ data }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const tabs = ['Specifications & Features', 'User Reviews'];
  
    return (
      <Pane id="product-description" marginBottom={16}>
        <Heading size={700} marginBottom={16}>Product Description</Heading>
        <Tablist marginBottom={16} flexBasis={240}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab}
              id={tab}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`panel-${tab}`}
            >
              {tab}
            </Tab>
          ))}
        </Tablist>
        <Pane>
          {selectedIndex === 0 && (
            <Pane key="Specifications & Features" role="tabpanel" aria-labelledby="Specifications & Features">
              <ProductSpecifications specifications={data.specifications} features={data.specialFeatures} />
            </Pane>
          )}
          {selectedIndex === 1 && (
            <Pane key="User Reviews" role="tabpanel" aria-labelledby="User Reviews">
              <UsersReviewSummary reviews={data.userReviewSummary} />
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  };
  
  const HealthData = ({ text }) => (
    <Pane id="health-data" marginBottom={16}>
      <Heading size={700} marginBottom={16}>Health Data</Heading>
      <Card padding={16}>
        <Text>{text}</Text>
      </Card>
    </Pane>
  );
  
 
  const HowToUse = ({ steps }) => {
    const stepsPerColumn = 3;
    const columns = [];
  
    for (let i = 0; i < steps.length; i += stepsPerColumn) {
      columns.push(steps.slice(i, i + stepsPerColumn));
    }
  
    return (
      <Pane id="how-to-use" marginBottom={16}>
        <Heading size={700} marginBottom={16}>How to Use</Heading>
        <Pane display="flex" flexDirection="row" gap={16}>
          {columns.map((column, columnIndex) => (
            <Pane key={columnIndex} flex="1">
              {column.map((step, index) => (
                <Card key={index} marginBottom={16} padding={16}>
                  <Heading size={600} marginBottom={8}>{index + 1 + columnIndex * stepsPerColumn}. {step.step}</Heading>
                  <UnorderedList style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    {step.instructions.map((instruction, idx) => (
                      <ListItem key={idx} style={{ textAlign: 'left' }}>{instruction}</ListItem>
                    ))}
                  </UnorderedList>
                </Card>
              ))}
            </Pane>
          ))}
        </Pane>
      </Pane>
    );
  };
  
 
  

  const HazardTable = ({ details }) => (
    <Pane marginBottom={16}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Specific Hazard</TableCell>
            <TableCell>Risk Level</TableCell>
            <TableCell>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((detail, index) => (
            <TableRow key={index}>
              <TableCell>{detail.specificHazard}</TableCell>
              <TableCell>{detail.riskLevel}</TableCell>
              <TableCell>{detail.comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Pane>
  );
  
  const Hazards = ({ data, additionalNotes }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const categories = data.map(hazard => hazard.category);
  
    return (
      <Pane id="hazards" marginBottom={16}>
        <Heading size={700} marginBottom={16}>Hazards</Heading>
        <Tablist marginBottom={16}>
          {categories.map((category, index) => (
            <Tab
              key={category}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
            >
              {category}
            </Tab>
          ))}
        </Tablist>
        <Pane>
          {data.map((hazard, index) => (
            <Pane
              key={hazard.category}
              role="tabpanel"
              aria-labelledby={hazard.category}
              display={index === selectedIndex ? 'block' : 'none'}
            >
              <HazardTable details={hazard.details} />
            </Pane>
          ))}
        </Pane>
        <Pane marginTop={16}>
          <Heading size={600} marginBottom={8}>Additional Notes</Heading>
          <UnorderedList style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {additionalNotes.map((note, index) => (
              <ListItem key={index}>{note}</ListItem>
            ))}
          </UnorderedList>
        </Pane>
      </Pane>
    );
  };
  
 
  
 
  const Media = ({ data }) => (
    <Pane flexBasis={300} padding={16} id="media">
      <Heading size={700} marginBottom={16}>Media</Heading>
      <Pane marginBottom={16}>
        <Heading size={600} marginBottom={8}>Images</Heading>
        {data.images.map((image, index) => (
          <Image key={index} src={image.src} alt={image.alt} width={200} marginBottom={8} />
        ))}
      </Pane>
      <Pane>
        <Heading size={600} marginBottom={8}>Videos</Heading>
        {data.videos.map((video, index) => (
          <Pane key={index} marginBottom={8}>
            <a href={video.src} target="_blank" rel="noopener noreferrer">
              <Image src={`https://img.youtube.com/vi/${video.src.split('v=')[1]}/default.jpg`} alt="YouTube video thumbnail" width={200} />
            </a>
          </Pane>
        ))}
      </Pane>
    </Pane>
  );
  
   
  
  const ProductPage = () => (
    <Pane display="flex" padding={16}>
      {/* Left Column: Table of Contents and Affiliate Links */}
      <Pane flexBasis={200} padding={16}>
        <TableOfContents items={productPageData.tableOfContents} />
        <AffiliateLinks links={productPageData.affiliateLinks} />
      </Pane>
  
      {/* Center Column: Product Description, Health Data, How to Use */}
      <Pane flex="1" padding={16}>
        <ProductDescription data={productPageData.productDescription} />
        <Hazards data={productPageData.hazards} additionalNotes={productPageData.additionalNotes} />
         <HowToUse steps={productPageData.howToUse} />
 
      </Pane>
  
      {/* Right Column: Media */}
      <Media data={productPageData.media} />
    </Pane>
  );
  
 
  

export default ProductPage;
