import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Pane, Heading, Link, UnorderedList, ListItem } from 'evergreen-ui';
 import '../css/header.css';

const Header = () => (
    <Pane 
    id="main-navbar" 
    className="navbar" 
    display="flex" 
    alignItems="center" 
    justifyContent="space-between" 
    padding={16} 
    backgroundColor="#282c34" 
    position="fixed" 
    top={0} 
    width="100%" 
    zIndex={1000}
  >
    <Heading size={600} color="white">Loopsights</Heading>
    <nav>
      <UnorderedList display="flex" listStyleType="none" margin={0} padding={0}>
        <ListItem marginRight={16}>
          <Link is={RouterLink} to="/" color="white">Home</Link>
        </ListItem>
        {/* <ListItem marginRight={16}>
          <Link is={RouterLink} to="/product" color="white">Product Page</Link>
        </ListItem>
        <ListItem marginRight={16}>
          <Link href="/about" color="white">About</Link>
        </ListItem>
        <ListItem>
          <Link href="/contact" color="white">Contact</Link>
        </ListItem> */}
      </UnorderedList>
    </nav>
  </Pane>
);

export default Header;
