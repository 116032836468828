
// substances.js
const substances = [
    "Aflatoxins",
    "Alcoholic Beverage Consumption",
    "4\u2011Aminobiphenyl",
    "Analgesic Mixtures Containing Phenacetin (see Phenacetin and Analgesic Mixtures Containing Phenacetin)",
    "Aristolochic Acids",
    "Arsenic and Inorganic Arsenic Compounds",
    "Asbestos",
    "Azathioprine",
    "Benzene",
    "Benzidine (see Benzidine and Dyes Metabolized to Benzidine)",
    "Beryllium and Beryllium Compounds",
    "Bis(chloromethyl) Ether and Technical-Grade Chloromethyl Methyl Ether",
    "1,3-Butadiene",
    "1,4-Butanediol Dimethanesulfonate",
    "Cadmium and Cadmium Compounds",
    "Chlorambucil",
    "1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea (see Nitrosourea Chemotherapeutic Agents)",
    "Chromium Hexavalent Compounds",
    "Coal Tars and Coal-Tar Pitches",
    "Coke-Oven Emissions",
    "Cyclophosphamide",
    "Cyclosporin A",
    "Diethylstilbestrol",
    "Dyes Metabolized to Benzidine (Benzidine Dye Class) (see Benzidine and Dyes Metabolized to Benzidine)",
    "Epstein-Barr Virus (see Viruses: Eight Listings)",
    "Erionite",
    "Estrogens, Steroidal",
    "Ethylene Oxide",
    "Formaldehyde",
    "Helicobacter pylori (Chronic Infection)",
    "Hepatitis B Virus (see Viruses: Eight Listings)",
    "Hepatitis C Virus (see Viruses: Eight Listings)",
    "Human Immunodeficiency Virus Type 1 (see Viruses: Eight Listings)",
    "Human Papillomaviruses: Some Genital-Mucosal Types (see Viruses: Eight Listings)",
    "Human T-Cell Lymphotrophic Virus Type 1 (see Viruses: Eight Listings)",
    "Kaposi Sarcoma\u2013Associated Herpesvirus (see Viruses: Eight Listings)",
    "Melphalan",
    "Merkel Cell Polyomavirus (see Viruses: Eight Listings)",
    "Methoxsalen with Ultraviolet A Therapy",
    "Mineral Oils: Untreated and Mildly Treated",
    "Mustard Gas",
    "2-Naphthylamine",
    "Neutrons (see Ionizing Radiation)",
    "Nickel Compounds (see Nickel Compounds and Metallic Nickel)",
    "Radon (see Ionizing Radiation)",
    "Silica, Crystalline (Respirable Size)",
    "Solar Radiation",
    "Soots",
    "Strong Inorganic Acid Mists Containing Sulfuric Acid",
    "Sunlamps or Sunbeds, Exposure to (see Ultraviolet Radiation Related Exposures)",
    "Tamoxifen",
    "2,3,7,8-Tetrachlorodibenzo-p-dioxin",
    "Thiotepa",
    "Thorium Dioxide (see Ionizing Radiation)",
    "Tobacco Smoke, Environmental (see Tobacco-Related Exposures)",
    "Tobacco Smoking (see Tobacco-Related Exposures)",
    "Tobacco, Smokeless (see Tobacco-Related Exposures)",
    "o\u2011Toluidine",
    "Trichloroethylene",
    "Ultraviolet Radiation, Broad-Spectrum (see Ultraviolet Radiation Related Exposures)",
    "Vinyl Chloride (see Vinyl Halides [selected])",
    "Wood Dust",
    "X-Radiation and Gamma Radiation (see Ionizing Radiation)",
    "Reasonably Anticipated To Be Human Carcinogens",
    "Acetaldehyde",
    "2\u2011Acetylaminofluorene",
    "Acrylamide",
    "Acrylonitrile",
    "Adriamycin",
    "2\u2011Aminoanthraquinone",
    "o\u2011Aminoazotoluene",
    "1-Amino-2,4-dibromoanthraquinone",
    "2\u2011Amino-3,4-dimethylimidazo[4,5\u2011f]\u00ad quinoline (see Heterocyclic Amines [Selected])",
    "2\u2011Amino-3,8-dimethylimidazo[4,5\u2011f]\u00ad quinoxaline (see Heterocyclic Amines [Selected])",
    "1\u2011Amino-2-methylanthraquinone",
    "2\u2011Amino-3-methylimidazo[4,5\u2011f]quinoline (see Heterocyclic Amines [Selected])",
    "2\u2011Amino-1-methyl-6-phenylimidazo[4,5\u2011b]\u00ad pyridine (see Heterocyclic Amines [Selected])",
    "Amitrole",
    "o-Anisidine and Its Hydrochloride",
    "Antimony Trioxide",
    "Azacitidine",
    "Basic Red 9 Monohydrochloride",
    "Benz[a]anthracene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Benzo[b]fluoranthene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Benzo[j]fluoranthene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Benzo[k]fluoranthene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Benzo[a]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Benzotrichloride",
    "2,2-Bis(bromomethyl)-1,3-propanediol (Technical Grade)",
    "Bis(chloroethyl) Nitrosourea (see Nitrosourea Chemotherapeutic Agents)",
    "Bromochloroacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "Bromodichloroacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "Bromodichloromethane",
    "1-Bromopropane",
    "Butylated Hydroxyanisole",
    "Captafol",
    "Carbon Tetrachloride",
    "Ceramic Fibers (Respirable Size)",
    "Chloramphenicol",
    "Chlorendic Acid",
    "Chlorinated Paraffins (C12, 60% Chlorine)",
    "Chlorodibromoacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "1-(2-Chloroethyl)-3-cyclohexyl-1-nitrosourea (see Nitrosourea Chemotherapeutic Agents)",
    "Chloroform",
    "3-Chloro-2-methylpropene",
    "4-Chloro-o-phenylenediamine",
    "Chloroprene",
    "p-Chloro-o-toluidine and Its Hydrochloride",
    "Chlorozotocin (see Nitrosourea Chemotherapeutic Agents)",
    "Cisplatin",
    "Cobalt and Cobalt Compounds That Release Cobalt Ions In Vivo (see Cobalt-Related Exposures)",
    "Cobalt\u2013Tungsten Carbide: Powders and Hard Metals (see Cobalt-Related Exposures)",
    "p\u2011Cresidine",
    "Cumene",
    "Cupferron",
    "Dacarbazine",
    "Danthron",
    "2,4\u2011Diaminoanisole Sulfate",
    "2,4\u2011Diaminotoluene",
    "Diazoaminobenzene",
    "Dibenz[a,h]acridine (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenz[a,j]acridine (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenz[a,h]anthracene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "7H-Dibenzo[c,g]carbazole (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenzo[a,e]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenzo[a,h]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenzo[a,i]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibenzo[a,l]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Dibromoacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "1,2-Dibromo-3-chloropropane",
    "1,2-Dibromoethane",
    "2,3-Dibromo-1-propanol",
    "Dichloroacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "1,4-Dichlorobenzene",
    "3,3\u2032-Dichlorobenzidine and Its Dihydrochloride",
    "Dichlorodiphenyltrichloroethane",
    "1,2-Dichloroethane",
    "Dichloromethane",
    "1,3-Dichloropropene (Technical Grade)",
    "Diepoxybutane",
    "Diesel Exhaust Particulates",
    "Di(2-ethylhexyl) Phthalate",
    "Diethyl Sulfate",
    "Diglycidyl Resorcinol Ether",
    "3,3\u2032-Dimethoxybenzidine (see 3,3\u2032-Dimethoxybenzidine and Dyes Metabolized to 3,3\u2032-Dimethoxybenzidine)",
    "4\u2011Dimethylaminoazobenzene",
    "3,3\u2032-Dimethylbenzidine (see 3,3\u2032-Dimethylbenzidine and Dyes Metabolized to 3,3\u2032-Dimethylbenzidine)",
    "Dimethylcarbamoyl Chloride",
    "1,1\u2011Dimethylhydrazine",
    "Dimethyl Sulfate",
    "Dimethylvinyl Chloride",
    "1,6-Dinitropyrene (see Nitroarenes [Selected])",
    "1,8-Dinitropyrene (see Nitroarenes [Selected])",
    "1,4-Dioxane",
    "Disperse Blue 1",
    "Dyes Metabolized to 3,3\u2032-Dimethoxybenzidine (3,3\u2032-Dimethoxybenzidine Dye Class)",
    "(see 3,3\u2032-Dimethoxybenzidine and Dyes Metabolized to 3,3\u2032-Dimethoxybenzidine)",
    "Dyes Metabolized to 3,3\u2032-Dimethylbenzidine (3,3\u2032-Dimethylbenzidine Dye Class)",
    "(see 3,3\u2032-Dimethylbenzidine and Dyes Metabolized to 3,3\u2032-Dimethylbenzidine)",
    "Epichlorohydrin",
    "Ethylene Thiourea",
    "Ethyl Methanesulfonate",
    "Furan",
    "Glass Wool Fibers (Inhalable), Certain",
    "Glycidol",
    "Hexachlorobenzene",
    "Hexachloroethane",
    "Hexamethylphosphoramide",
    "Hydrazine and Hydrazine Sulfate",
    "Hydrazobenzene",
    "Indeno[1,2,3-cd]pyrene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "Iron Dextran Complex",
    "Isoprene",
    "Kepone",
    "Lead and Lead Compounds",
    "Lindane, Hexachlorocyclohexane (Technical Grade), and Other Hexachlorocyclohexane Isomers",
    "2-Methylaziridine",
    "5-Methylchrysene (see Polycyclic Aromatic Hydrocarbons: 15 Listings)",
    "4,4\u2032-Methylenebis(2-chloroaniline)",
    "4,4\u2032-Methylenebis(N,N-dimethyl)\u00ad benzenamine",
    "4,4\u2032\u2011Methylenedianiline and Its Dihydrochloride",
    "Methyleugenol",
    "Methyl Methanesulfonate",
    "N-Methyl-N\u2032",
    "-Nitro-N-Nitrosoguanidine (see N-Nitrosamines: 15 Listings)",
    "Metronidazole",
    "Michler\u2019s Ketone",
    "Mirex",
    "Naphthalene",
    "Nickel, Metallic (see Nickel Compounds and Metallic Nickel)",
    "Nitrilotriacetic Acid",
    "o-Nitroanisole",
    "Nitrobenzene",
    "6-Nitrochrysene (see Nitroarenes [Selected])",
    "Nitrofen",
    "Nitrogen Mustard Hydrochloride",
    "Nitromethane",
    "2-Nitropropane",
    "1-Nitropyrene (see Nitroarenes [Selected])",
    "4-Nitropyrene (see Nitroarenes [Selected])",
    "N-Nitrosodi-n-butylamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosodiethanolamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosodiethylamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosodimethylamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosodi-n-propylamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitroso-N-ethylurea (see N-Nitrosamines: 15 Listings)",
    "4-(N-Nitrosomethylamino)-1-(3-pyridyl)-1-butanone (see N-Nitrosamines: 15 Listings)",
    "N-Nitroso-N-methylurea (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosomethylvinylamine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosomorpholine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosonornicotine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosopiperidine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrosopyrrolidine (see N-Nitrosamines: 15 Listings)",
    "N-Nitrososarcosine (see N-Nitrosamines: 15 Listings)",
    "o-Nitrotoluene",
    "Norethisterone",
    "Ochratoxin A",
    "4,4\u2032\u2011Oxydianiline",
    "Oxymetholone",
    "Pentachlorophenol and By-products of Its Synthesis",
    "Phenacetin (see Phenacetin and Analgesic Mixtures Containing Phenacetin)",
    "Phenazopyridine Hydrochloride",
    "Phenolphthalein",
    "Phenoxybenzamine Hydrochloride",
    "Phenytoin and Phenytoin Sodium",
    "Polybrominated Biphenyls",
    "Polychlorinated Biphenyls",
    "Procarbazine and Its Hydrochloride",
    "Progesterone",
    "1,3\u2011Propane Sultone",
    "\u03b2\u2011Propiolactone",
    "Propylene Oxide",
    "Propylthiouracil",
    "Reserpine",
    "Riddelliine",
    "Safrole",
    "Selenium Sulfide",
    "Streptozotocin (see Nitrosourea Chemotherapeutic Agents)",
    "Styrene",
    "Styrene-7,8-oxide",
    "Sulfallate",
    "Tetrachloroethylene",
    "Tetrafluoroethylene",
    "Tetranitromethane",
    "Thioacetamide",
    "4,4\u2032-Thiodianiline",
    "Thiourea",
    "Toluene Diisocyanates",
    "Toxaphene",
    "Tribromoacetic Acid (see Haloacetic Acids Found as Water Disinfection By-Products [Selected])",
    "2,4,6-Trichlorophenol",
    "1,2,3-Trichloropropane",
    "Tris(2,3-dibromopropyl) Phosphate",
    "Ultraviolet Radiation A (see Ultraviolet Radiation Related Exposures)",
    "Ultraviolet Radiation B (see Ultraviolet Radiation Related Exposures)",
    "Ultraviolet Radiation C (see Ultraviolet Radiation Related Exposures)",
    "Urethane",
    "Vinyl Bromide (see Vinyl Halides [Selected])",
    "4-Vinyl-1-cyclohexene Diepoxide",
    "Vinyl Fluoride (see Vinyl Halides [Selected])"
]

export default substances;