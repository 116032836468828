// store.js
import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';

const useStore = create(set => ({
  productInfo: '',
  foundSubstances: [],
  result: '',
  queries: [],
  setProductInfo: info => set({ productInfo: info }),
  setFoundSubstances: substances => set({ foundSubstances: substances }),
  setResult: result => set({ result }),
  addQuery: query => set(state => ({ queries: [...state.queries, query] })),
}));

export default useStore;
