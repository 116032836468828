// AppBackendManager.js
import { db } from './firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import axios from 'axios';

class AppBackendManager {
  // Add a new query to Firestore with timestamp
  async addQuery(queryData) {
    try {
      const timestamp = new Date().toISOString();
      const dataWithTimestamp = { ...queryData, timestamp };
      const docRef = await addDoc(collection(db, 'queries'), dataWithTimestamp);
      console.log('Document written with ID: ', docRef.id);
      return docRef.id;
    } catch (e) {
      console.error('Error adding document: ', e);
      throw e;
    }
  }

  // Fetch queries from Firestore based on criteria
  async fetchQueries(criteria = {}) {
    try {
      const q = query(collection(db, 'queries'), ...Object.keys(criteria).map(key => where(key, '==', criteria[key])));
      const querySnapshot = await getDocs(q);
      const queries = [];
      querySnapshot.forEach(doc => {
        queries.push({ id: doc.id, ...doc.data() });
      });
      return queries;
    } catch (e) {
      console.error('Error fetching documents: ', e);
      throw e;
    }
  }

  // Call backend API
  async callBackendAPI(endpoint, data) {
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (e) {
      console.error('Error calling backend API: ', e);
      throw e;
    }
  }
}

export default new AppBackendManager();
