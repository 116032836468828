
// // app/src/views/productchecker.js
// import React from 'react';
// import { Pane, Text, Textarea, Button, Paragraph, Table, Heading } from 'evergreen-ui';
// import substances from '../assets/substances'; // Adjust the path as needed
// import useStore from '../appservices/store'; // Adjust the path as needed
// import { v4 as uuidv4 } from 'uuid';
// import AppBackendManager from '../appservices/appbackendmanager'; // Adjust the path as needed

// function ProductChecker() {
//   const productInfo = useStore(state => state.productInfo);
//   const foundSubstances = useStore(state => state.foundSubstances);
//   const result = useStore(state => state.result);
//   const queries = useStore(state => state.queries);
//   const setProductInfo = useStore(state => state.setProductInfo);
//   const setFoundSubstances = useStore(state => state.setFoundSubstances);
//   const setResult = useStore(state => state.setResult);
//   const addQuery = useStore(state => state.addQuery);

//   const handleCheckProduct = async () => {
//     if (!Array.isArray(substances) || !substances.length) {
//       setResult('Unable to load substances list.');
//       return;
//     }

//     const productWords = productInfo.toLowerCase().split(/\W+/);
//     const found = substances.filter(substance => {
//       return productWords.some(word => substance.toLowerCase().includes(word));
//     });

//     setFoundSubstances(found);
//     const queryId = uuidv4();
//     const queryResult = found.length > 0 ? `The product contains the following carcinogenic substances: ${found.join(', ')}` : 'No carcinogenic substances found in the product.';
    
//     const queryData = { id: queryId, productInfo, result: queryResult };
//     addQuery(queryData);
//     await AppBackendManager.addQuery(queryData);

//     if (found.length > 0) {
//       setResult(`The product contains the following carcinogenic substances:`);
//     } else {
//       setResult('No carcinogenic substances found in the product.');
//     }
//   };

//   return (
//     <Pane display="flex" flexDirection="column" padding={24}>
//       <Pane display="flex" flexDirection="row">
//         <Pane
//           elevation={1}
//           backgroundColor="white"
//           width={400}
//           height={350}
//           margin={24}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           flexDirection="column"
//           padding={16}
//         >
//           <Heading size={600}>Provide Product Description and Constituents</Heading>
//           <Textarea
//             placeholder="Enter product description and constituents, separated by commas..."
//             value={productInfo}
//             onChange={e => setProductInfo(e.target.value)}
//             marginBottom={16}
//             width="100%"
//             height={120}
//           />
//           <Button appearance="primary" onClick={handleCheckProduct}>
//             Check Product
//           </Button>
//         </Pane>
//         <Pane
//           elevation={1}
//           backgroundColor="white"
//           width={500}
//           margin={24}
//           padding={16}
//           display="flex"
//           flexDirection="column"
//         >
//           <Heading size={600}>Detection Results</Heading>
//           {result ? (
//             <>
//               <Paragraph>{result}</Paragraph>
//               {foundSubstances.length > 0 && (
//                 <Table>
//                   <Table.Head>
//                     <Table.TextHeaderCell>Carcinogenic Substances</Table.TextHeaderCell>
//                   </Table.Head>
//                   <Table.Body>
//                     {foundSubstances.map((substance, index) => (
//                       <Table.Row key={index}>
//                         <Table.TextCell>{substance}</Table.TextCell>
//                       </Table.Row>
//                     ))}
//                   </Table.Body>
//                 </Table>
//               )}
//             </>
//           ) : (
//             <Paragraph>Enter product details to check for carcinogens.</Paragraph>
//           )}
//         </Pane>
//       </Pane>
//       <Pane margin={24}>
//         <Paragraph>
//           <strong>Disclaimer:</strong> <small>While we work to ensure that product information is correct, on occasion manufacturers may alter their ingredient lists. Actual product packaging and materials may contain more and/or different information than that shown on our Web site. We recommend that you do not solely rely on the information presented and that you always read labels, warnings, and directions before using or consuming a product. For additional information about a product, please contact the manufacturer. Content on this site is for reference purposes and is not intended to substitute for advice given by a physician, pharmacist, or other licensed health-care professional. You should not use this information as self-diagnosis or for treating a health problem or disease. Contact your health-care provider immediately if you suspect that you have a medical problem. Information and statements regarding dietary supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease or health condition. Loopsights.com assumes no liability for inaccuracies or misstatements about products.</small>
//         </Paragraph>
//       </Pane>
//     </Pane>
//   );
// }

// export default ProductChecker;



import React , {useEffect} from 'react';
import { Pane, Heading,Paragraph,Table, Textarea, Button } from 'evergreen-ui';
import useStore from '../appservices/store'; // Adjust the path as needed
import { v4 as uuidv4 } from 'uuid';
import AppBackendManager from '../appservices/appbackendmanager'; // Adjust the path as needed
import substances from '../assets/substances'; // Adjust the path as needed
import Mixpanel from '../utils/mixpanel';
 
function InputPane() {
  const productInfo = useStore(state => state.productInfo);
  const setProductInfo = useStore(state => state.setProductInfo);
  const setFoundSubstances = useStore(state => state.setFoundSubstances);
  const setResult = useStore(state => state.setResult);
  const addQuery = useStore(state => state.addQuery);

  const handleCheckProduct = async () => {
    if (!Array.isArray(substances) || !substances.length) {
      setResult('Unable to load substances list.');
      return;
    }

    const productWords = productInfo.toLowerCase().split(/\W+/);
    const found = substances.filter(substance => {
      return productWords.some(word => substance.toLowerCase().includes(word));
    });

    setFoundSubstances(found);
    const queryId = uuidv4();
    const queryResult = found.length > 0 ? `The product contains the following carcinogenic substances: ${found.join(', ')}` : 'No carcinogenic substances found in the product.';
    
    const queryData = { id: queryId, productInfo, result: queryResult, timestamp: new Date().toISOString() };
    addQuery(queryData);
    await AppBackendManager.addQuery(queryData);

    // Track event with Mixpanel
    Mixpanel.track('Product Checked', {
      queryId,
      productInfo,
      result: queryResult
    });

    if (found.length > 0) {
      setResult(`The product contains the following carcinogenic substances:`);
    } else {
      setResult('No carcinogenic substances found in the product.');
    }
  };

  return (
    <Pane
      elevation={1}
      backgroundColor="white"
      width={400}
      height={350}
      margin={24}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={16}
    >
      <Heading size={600}>Provide Product Description and Constituents</Heading>
      <Textarea
        placeholder="Enter product description and constituents, separated by commas..."
        value={productInfo}
        onChange={e => setProductInfo(e.target.value)}
        marginBottom={16}
        width="100%"
        height={120}
      />
      <Button appearance="primary" onClick={handleCheckProduct}>
        Check Product
      </Button>
    </Pane>
  );
}





 

  function ResultPane() {
    const foundSubstances = useStore(state => state.foundSubstances);
    const result = useStore(state => state.result);
  
    if (!result) {
      return null;
    }
  
    return (
      <Pane
        elevation={1}
        backgroundColor="white"
        width={500}
        margin={24}
        padding={16}
        display="flex"
        flexDirection="column"
        maxHeight={400} // Set max height
        overflowY="auto" // Make scrollable if content exceeds max height
      >
        <Heading size={600}>Detection Results</Heading>
        <Paragraph>{result}</Paragraph>
        {foundSubstances.length > 0 && (
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Carcinogenic Substances</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {foundSubstances.map((substance, index) => (
                <Table.Row key={index}>
                  <Table.TextCell>{substance}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Pane>
    );
  }
  
  



  function ProductChecker() {
    const result = useStore(state => state.result);
  
    useEffect(() => {
      // Track page view event with Mixpanel
      Mixpanel.track('Page Viewed', {
        page: 'ProductChecker'
      });
    }, []);
  
    return (
      <Pane display="flex" flexDirection="column" padding={24}>
        <Pane display="flex" flexDirection={result ? "row" : "column"} alignItems="center" justifyContent="center">
          <InputPane />
          {result && <ResultPane />}
        </Pane>
        <Pane margin={24}>
          <Paragraph>
            <strong>Disclaimer:</strong> <small>While we work to ensure that product information is correct, on occasion manufacturers may alter their ingredient lists. Actual product packaging and materials may contain more and/or different information than that shown on our Web site. We recommend that you do not solely rely on the information presented and that you always read labels, warnings, and directions before using or consuming a product. For additional information about a product, please contact the manufacturer. Content on this site is for reference purposes and is not intended to substitute for advice given by a physician, pharmacist, or other licensed health-care professional. You should not use this information as self-diagnosis or for treating a health problem or disease. Contact your health-care provider immediately if you suspect that you have a medical problem. Information and statements regarding dietary supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease or health condition. Loopsights.com assumes no liability for inaccuracies or misstatements about products.</small>
          </Paragraph>
        </Pane>
      </Pane>
    );
  }
  
  

export default ProductChecker;

