// path: webapp/src/views/homepage.js

import React from 'react';
import { Pane, Heading, Paragraph, Button, Text } from 'evergreen-ui';
import { Link as RouterLink } from 'react-router-dom';

import ProductChecker from './productchecker';









const HomePage = () => {
  return (
    <Pane display="flex" flexDirection="column" alignItems="center" margin={50}>
      <Paragraph size={500} textAlign="center" maxWidth={600} marginBottom={32}>

    
      Loopsights is an AI health security company developing carcinogen detection systems.

        Our Mission is to prevent cancer. 
       </Paragraph>
      


      <ProductChecker />
    </Pane>
  );
};

export default HomePage;
